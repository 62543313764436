<template>
    <div class="container">
        <div class='title'> Hoursheet </div>
        <div class='periods'>

            <div class='total'> Total: {{ data.total }}.- MXN </div>
<!-- 
            <button @click.stop="confirm_hoursheet" class="hoursheet-confirm">Confirm Hoursheet</button>
            <button @click.stop="confirm_hoursheet" class="hoursheet-send-ticket">Send Change Request</button> -->

    
            <div class="period">
                <div class="group" v-for="(group,key,index) in data.groups">
                    <div class="group-title">
                         Group: {{ index+1 }} 
                        <template v-for="student in group.group.student">
                            {{ student.ui }}.
                        </template>    
                    </div> 

                    <table>
                        <thead>
                            <th>MXN.-</th> 
                            <th>Date</th> 
                            <th>Duration</th> 
                            <th>Instructor Confirmation</th> 
                            <th>Student Confirmation</th> 

                        </thead>
                        <tbody>
                            <tr class="attendance" v-for="attendance in group.attendances"> 
                                
                                <td>
                                    {{ attendance.pay }}.-
                                </td>
                                <!-- <div>
                                    {{ attendance.id }}
                                </div> -->
                                <td>
                                    {{ attendance.datetime.slice(0,10) }}
                                </td>
                                <td>
                                    {{ attendance.duration }} hrs.
                                </td>
                                <td class="instructor-confirmation">
                                    <template v-if="attendance.instructor_confirmation.at(-1)">
                                        {{ attendance.instructor_confirmation.at(-1).ui }}
                                    </template>
                                </td>
                                <td class="student-confirmations">
            
                                    <template v-for="confirmation in attendance.student_confirmations">
                                        <template v-if="confirmation">
                                            <div class="student-confirmation">
                                                {{ confirmation.ui }}
                                            </div>
                                        </template>
                                    </template>
                                </td>
                                
            
                            </tr>

                        </tbody>

                    </table>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
    import axios from 'axios'
    import {reactive,ref,onBeforeMount,onMounted} from 'vue'
    import {useStore} from 'vuex'
    import {useRoute} from 'vue-router'


    let route=useRoute()



    let store=useStore()

    let data=reactive({})
    let user=store.state.user


    

    onBeforeMount(async ()=>{
        if (user.role.includes('Admin')){
            // let user_id=prompt('user id')
            let user_id=route.query.user_id

            user.id=user_id

            }
        let response=await axios.post('/instructors/get_instructor/',{user_id:user.id})
        data.instructor=response.data
        console.log(data)

    })

    onMounted(async ()=>{
        let response=await axios.post('/instructors/hoursheet/',{user_id:user.id})
        data.attendances=response.data
        console.log(data.attendances)
        let group_ids=[]
        data.attendances.forEach(attendance=>{
            if(!attendance.group){

            }else{
                group_ids.push(attendance.group.id)

            }
        })

        group_ids=new Set(group_ids)

        let groups=[]
        group_ids.forEach(group_id=>{
            data.attendances.every(attendance=>{
                if(!attendance.group){

                }else{

                    console.log(attendance.group.id, group_id, group_id==attendance.group.id)
                    if(attendance.group.id==group_id){
                        groups.push(attendance.group)
                        return false
                    }
                }
                return true
            })
        })


        // groups=Array.from(new Set(groups))
        console.log(group_ids)
        console.log(groups)
        data.groups={}
        data.total=0

        groups.forEach(group=>{

            let groupattendances=data.attendances.filter(attendance=>{
                if(!attendance.group){

                }else{
                    return attendance.group.id==group.id
                }
            })


            groupattendances=groupattendances.sort((a,b)=>{
                return new Date(a.datetime) - new Date(b.datetime)
            })

            groupattendances.forEach(attendance=>{
                attendance.student_confirmations={}
                let students=[]
                attendance.student_confirmation.forEach(student_confirmation=>{
                    students.push(student_confirmation.student)
                })
                students=Array.from(new Set(students))

                students.forEach(student=>{
                    attendance.student_confirmations[student] = attendance.student_confirmation.filter(student_confirmation=>{
                        return student_confirmation.student == student
                    })
                    
                    attendance.student_confirmations[student]=attendance.student_confirmations[student].at(-1)
                })
            })

            groupattendances.forEach(attendance=>{
                // attendance.pay=(Number(attendance.pay)*Number(attendance.duration))
                data.total+=Number(attendance.pay)
            })

            data.groups[group.id]={
                attendances:groupattendances,
                group:group,                        
            }



        })
        console.log(data)


    })



</script>



<style scoped>
    .container{
        display: grid;
        justify-content:center;
        justify-items: center;
        align-items:center;
        text-align: center;
        max-width: 100%;
        overflow:hidden;

    }

    .periods{
        display: grid;
        width: 100vw;
        max-width: 100vw;

        gap:1rem;
        background: var(--gray);
        color:var(--white);
        justify-content:center;
        justify-items: center;
        /* align-items:center; */
        text-align: center;

    }

    .title{
        font-size:xx-large;
    }

    .total{
        font-size: xx-large;
        color:var(--white);
        padding:1rem;
        background: var(--green);
        color:var(--black);
        width:fit-content;
        border-radius: 1rem;
        border: 5px solid var(--red);
    }

    .period{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        max-width: 100vw;        
        justify-content:center;
        align-items:center;
        padding: 1rem;
    }

    .group{
        display: flex;
        flex-direction: column;
        gap:1rem;
        background: var(--red);
        border:5px solid var(--blue);
        border-radius:1rem;
        justify-content:center;
        align-items:center;
        width: fit-content;
        max-width: 100vw;
        padding: 1rem;
        overflow:auto;

    }

    .group-title{
        font-size: xx-large;
    }

    .attendance{
        background: var(--blue);
        font-size: x-large;
        justify-content: center;
        align-items: center;
        align-content: space-around;
        justify-items: center;
        width: 100%;
        padding:1rem;
    }

    table{
        border-collapse: collapse;
    }

    tr{
        border-top:5px solid var(--white);
        border-bottom:5px solid var(--white);
        
    }

    td {
        padding: 1rem;
    }

</style>