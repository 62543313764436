<template>

    <div class="communications">
        <div class="contacts"  :class="{toggle_contacts:data.toggle_contacts}">
            <div @click.stop="get_messages(contact)" class="contact" :class="{selected:contact==data.selected_contact,toggle_contacts:data.toggle_contacts}"  v-for="contact in data.contacts">
                {{contact.user}} {{ contact.number }}
            </div>
    
        </div>


        <div @click.stop="toggle_contacts()" class="slider"></div>


        <div class="messages">
            <div class="message" :class="{left:message.direction.includes('inbound'),right:message.direction.includes('outbound')}" v-for="message in data.selected_contact.messages">
                <template v-if="message.direction.includes('inbound')">
                    {{data.selected_contact.user}}: {{ message.body }}

                </template> 
                <template v-else>
                    InterAct: {{ message.body }}
                </template>
                <div class="status">{{message.status}}</div>
            </div>
        </div>

        <template v-if="data.selected_contact.user!=''">
            <div class="message-input">
                <input @keypress.enter.stop="send_message()" v-model="data.selected_contact.pending_message" placeholder="Write message here">
            </div>
        </template>
    </div>

</template>



<script setup>

    import {reactive, onBeforeMount, resolveComponent} from 'vue'
    import axios from 'axios'

    let data=reactive({
        contacts:[],
        toggle_contacts:true,
        selected_contact:{
            user:'',
            number:'',
            pending_message:'',
            messages:'',
        },

    })

    onBeforeMount(async ()=>{
        let response=await axios.post('/twilio/get_contacts/')
        data.contacts=response.data


    })


    async function get_messages(contact){
        let response=await axios.post('/twilio/get_messages/',{contact:contact})
        console.log(contact,response.data)
        contact.messages=response.data
        data.selected_contact=contact

        scrollToBottom()

    }
    
    async function send_message(){
        let body=data.selected_contact.pending_message
        data.selected_contact.pending_message=''
        let number=data.selected_contact.number

        let response=await axios.post('/twilio/send_message/',{number:number,body:body})
        let message=response.data
        data.selected_contact.messages.push(message)
        // data.selected_contact.messages.push({ 
        //     body: "Message sent successfully", 
        //     direction: "outbound-system" 
        // });
        scrollToBottom()

    }



    function scrollToBottom() {
        let container = document.querySelector('.messages')
        console.log(container)
        setTimeout(()=>{
            container.scrollTop = container.scrollHeight

        },0)
    }



    function toggle_contacts(){
        console.log(data.toggle_contacts)
        data.toggle_contacts= !data.toggle_contacts
        console.log(data.toggle_contacts)

    }



</script>


<style scoped>

    .communications{
        display: grid;
        width: 100%;
        max-width: 100vw;
        height: 100%;
        max-height: 100vh;

        grid-template-areas: "contacts slider messages"
                             "contacts slider input";

        grid-template-rows: 1fr 80px;
        grid-template-columns: auto 15px 1fr;
        overflow: hidden;
    }

    .contacts{
        display: flex;
        flex-direction: column;
        
        width: 0;
        min-width: 0;
        max-width: 0;
        
        height: 100vh;
        max-height: 100vh;
        
        align-items: start;
        justify-content: start;

        grid-area: contacts;

        background:var(--gray);

        padding-left:1rem;

        overflow: auto;

    }




    .contact{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: x-large;
        background:var(--white);
        min-width: 0;
        width: 0;
        max-width: 0;
        height: 5rem;
        min-height: 5rem;
        overflow: auto;
        cursor: pointer;
        /* margin-left: 1rem; */

        border:var(--border);

    }


    .toggle_contacts{
        width: 30vw;
        min-width: 30vw;
        max-width: 30vw;
  
    }


    .selected{
        background: var(--darkgreen);
        color:var(--white);
    }



    .slider{
        height: 100%;
        width: 15px;
        background: var(--darkgreen);

        grid-area: slider;
    }












    .message-input{
        width: 100%;
        height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-area:input;
    }

    .message-input input{
        width: 100%;
    }



    .messages{
        grid-area: messages;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 100%;
        min-width: min-content;
        height: 100%;
        max-height: 100%;

        gap:10px;
        align-items: center;
        /* justify-content: end; */
        overflow: auto;

    }

    .message{
        display: flex;
        flex-direction: column;

    }

    .status{
        align-self: end;
        bottom: 0;
        right: 0;
        width: 100px;
        height: 20px;
        border-radius: 1rem;
        text-align: center;
        background: var(--gray);
    }



    .left{
        align-self: start;
        border:var(--border);
        background:var(--lightblue);
        color:var(--white);

    }

    .right{

        align-self:end;

        border:var(--border);
        background:var(--lightgreen);
        /* color:var(--white); */

    }




</style>