<template>
    <div class="title">
        Evaluation Reports
    </div>
    <div class="personal-information">
        <div class="personal-information1">
            <label>
                First Name:
                <div> first_name </div>
            </label>
            <label>
                Last Name:
                <div> last_name </div>
            </label>
            <label>
                Gender:
                <div> Gender </div>
            </label>
            <label>
                Cellphone:
                <div> Cellphone </div>
            </label>
            <label>
                E-mail:
                <div> E-mail </div>
            </label>
            <label>
                Age:
                <div> Age </div>
            </label>
            <label>
                Timezone:
                <div> Timezone </div>
            </label>
    
        </div>
    
        <div class="personal-information2">
            <label>
                Level:
                <div> Level </div>
            </label>
            <label>
                Profession:
                <div> Profession </div>
            </label>
        </div>
    </div>

    <div class="evaluations">
        <div class="evaluation" v-for="evaluation in data.evaluations">

            <div>
                <canvas ref="chartCanvas"></canvas>    
            </div>
        </div>
    </div>





    

</template>


<script setup>

import {reactive,ref,onMounted, onBeforeMount} from 'vue'
import axios from 'axios'
import Chart from 'chart.js/auto';  // Import Chart.js

import { useRoute } from 'vue-router';


const chartCanvas = ref(null); // Reference to the canvas

let data=reactive({
    evaluations:[],
    skills:[]

})



onBeforeMount(async ()=>{
    
    let route = useRoute();
    let user_id = route.query.user_id;

    data.evaluations=await axios.post('/evaluations/get_evaluations/',{user_id:user_id})
    data.skills=await axios.post('evaluations/get_skills/')

})


onMounted(()=>{
    const ctx = chartCanvas.value.getContext('2d');  // Get canvas context
    new Chart(ctx, {
    type: 'bar',
    data: {
      labels: data.skills,
      datasets: [{
        label: 'Sales',
        data: [12, 19, 3, 5, 2],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1
      }]
    },
    options: {
      responsive: false,

      plugins: {
        legend: {
          position: 'top',
        },
      },
    }
  })


})

</script>


<style scoped>

    label{
        display: flex;
        align-items: center;
    }

    .personal-information{
        display: flex;
        align-items: center;
        justify-content: start;
        width:100%;
        max-width:100vw;
    }

    .personal-information1,.personal-information2{
        width: 100%;
    }

    canvas{
        width: 1000px;
        height: 500px;
    }

</style>