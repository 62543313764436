<template>
    <div @click.stop="toggle_leads()" class='title'>
        Leads
    </div>

    <div class="leads-container" :class="{show:data.show, hide:!data.show}">

        <table class="leads-table">
            <thead>
                <tr>
                    <th>User</th>
                    <th>Level</th>
                    <th>Demo Date</th>
                    <th>Demo Time</th>
                    <th>Demo Instructor</th>
                    <th>Origin</th>
                    <th>Comments</th>
                </tr>
            </thead>

            <tbody>
                



                <tr v-for="lead in data.leads">
                    <td>{{ lead.user.first_name + ' ' + lead.user.last_name }}</td>
                    <td>{{ lead.level }}</td>
                    <td v-if="lead.demo">{{ new Date(lead.demo.datetime).toLocaleString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }) }}</td>
                    <td v-if="lead.demo">{{ new Date(lead.demo.datetime).toLocaleTimeString('en-US', { hour12: false }) }}</td>
                    <td v-if="lead.demo">{{ lead.demo.instructor }}</td>
                    <td v-if="lead.demo">{{ lead.demo.origin }}</td>
                    <td v-if="lead.demo">{{ lead.demo.comments }}</td>


                </tr>
            </tbody>
        </table>


    </div>





</template>



<script setup>
import { reactive, onMounted } from 'vue'
import axios from 'axios'



let data = reactive({
    show:false,
    leads: [],
})

onMounted(async ()=>{
    
    let response= await axios.post('/admin/get_leads/')

    data.leads=response.data



})


function toggle_leads(){
    data.show=!data.show
}




</script>




<style scoped>
/* Container styling */
.leads-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background: linear-gradient(135deg, var(--lightblue), var(--lightgreen));
    overflow: hidden;
}

/* Table wrapper */
.leads-table-wrapper {
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(6px);
    padding: 25px;
    border-radius: 12px;
    box-shadow: var(--shadow);
    color: var(--black);
    width: 90%;
    max-width: 1000px;
    position: relative;
    border: var(--inactive-border);
    overflow-x: auto;
}

/* Table styling */
.leads-table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 10px;
    overflow: hidden;
    font-size: 16px;
}

/* Header styling */
th {
    background: var(--blue);
    color: var(--white);
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
    padding: 16px;
    border-bottom: 2px solid var(--darkinactive);
}

/* Cell styling */
td {
    color:var(--black);
    background: rgba(255, 255, 255, 0.7);
    padding: 14px;
    border-bottom: 1px solid var(--inactive-border);
}

/* Alternating row colors for readability */
tr:nth-child(even) td {
    background: rgba(132, 189, 186, 0.3);
}

/* Hover effect */
tr:hover td {
    background: var(--lightblue);
    transition: background-color 0.3s ease;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .leads-table-wrapper {
        width: 95%;
        padding: 15px;
    }

    .leads-table {
        font-size: 14px;
    }

    th, td {
        padding: 12px;
    }
}
</style>



