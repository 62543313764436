<template>
    <img id="logo" :src="data.logo">

    <div class="schedule-container">
        <div class="schedule">
            <h2>Clase Gratis</h2>
            <form class="schedule-form">

                <div class="form-group">
                    <label for="name">Nombre:</label>
                    <input type="text" id="name" v-model="data.first_name" required>
                    <span class="info-icon" @mouseover="showPopup('Ingrese su nombre.')" @mouseleave="hidePopup">&#9432;</span>
                </div>
                
                <div class="form-group">
                    <label for="lastname">Apellido:</label>
                    <input type="text" id="lastname" v-model="data.last_name" required>
                    <span class="info-icon" @mouseover="showPopup('Ingrese su apellido.')" @mouseleave="hidePopup">&#9432;</span>
                </div>

                <div class="form-group">
                    <label for="date">Seleccionar Fecha de la Clase: {{ data.timezone }}</label>
                    <input type="date" id="date" v-model="data.date" required>
                    <span class="info-icon" @mouseover="showPopup('Seleccione la fecha en la que desea tomar la clase.')" @mouseleave="hidePopup">&#9432;</span>
                </div>
                
                <div class="form-group">
                    <label for="time">Seleccionar Hora de la Clase: {{ data.timezone }}</label>
                    <input type="time" id="time" v-model="data.time" required>
                    <span class="info-icon" @mouseover="showPopup('Seleccione la hora en la que desea tomar la clase.')" @mouseleave="hidePopup">&#9432;</span>
                </div>
                
                <div class="form-group">
                    <label for="email">Ingrese su Correo Electr&oacute;nico:</label>
                    <input type="email" id="email" v-model="data.email" placeholder="ejemplo@correo.com" required>
                    <span class="info-icon" @mouseover="showPopup('Ingrese su correo electr&oacute;nico para recibir confirmaci&oacute;n.')" @mouseleave="hidePopup">&#9432;</span>
                </div>
                
                <div class="form-group">
                    <label for="cellphone">Ingrese su N&uacute;mero de Tel&eacute;fono de Whatsapp:</label>
                    <input type="tel" id="cellphone" v-model="data.cellphone" placeholder="+XX XXXX XXXX" required>
                    <span class="info-icon" @mouseover="showPopup('Ingrese su n&uacute;mero de tel&eacute;fono para recordatorios.')" @mouseleave="hidePopup">&#9432;</span>
                </div>

                
                <div class="form-group">
                    <label for="level">Nivel de Ingl&eacute;s Actual:</label>
                    <select id="level" v-model="data.level" required>
                        <option value="">Seleccione</option>
                        <option value="beginner">Principiante (A1)</option>
                        <option value="intermediate">Básico-Intermedio (A2-B1)</option>
                        <option value="intermediate">Intermedio (B2)</option>
                        <option value="advanced">Avanzado (B2-C1)</option>
                    </select>
                    <span class="info-icon" @mouseover="showPopup('Seleccione su nivel de ingl&eacute;s actual.')" @mouseleave="hidePopup">&#9432;</span>
                </div>


                <div class="form-group">
                    <label for="comments">Comentarios:</label>
                    <textarea id="comments" v-model="data.comments" rows="3"></textarea>
                    <span class="info-icon" @mouseover="showPopup('Ingrese comentarios adicionales como objetivos y experiencia previa.')" @mouseleave="hidePopup">&#9432;</span>
                </div>
                
                <button @click.stop="schedule_demo()" type="button">Agendar Clase Gratis</button>
            </form>
            <div v-if="popupVisible" class="popup">{{ popupText }}</div>
        </div>
    </div>
</template>

<script setup>
import { ref,reactive,onMounted } from 'vue';
import axios from 'axios';




const popupVisible = ref(false);
const popupText = ref('');

let data=reactive({
    first_name: null,
    last_name: null,
    date: null,
    time: null,
    datetime:null,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    email: null,
    cellphone: null,
    level: null,
    comments: null,
    logo: require('@/assets/logo.png')

})



import {useRoute,useRouter} from 'vue-router'

let route=useRoute()


onMounted(()=>{
    data.origin=route.query.origin


})




async function schedule_demo() {

    data.datetime = new Date(`${data.date}T${data.time}:00.000`);
    data.datetime = data.datetime.toISOString().split('.')[0];
    let nullKeys = [];
    for (let [key, value] of Object.entries(data)) {
        console.log(`Key: ${key}, Value: ${value}`);
        if (value == null || value == "") {
            nullKeys.push(key);
        }
    }

    if (nullKeys.length > 0) {
        alert(`Please fill out the following fields: ${nullKeys.join(', ')}`);
        return;
    }

    console.log(data);
    await axios.post('/students/schedule_demo/', data);
}



function showPopup(text) {
    popupText.value = text;
    popupVisible.value = true;
}

function hidePopup() {
    popupVisible.value = false;
}
</script>





<style scoped>
body, html {
    height: 100%;
    margin: 0;
    font-family: var(--ubuntu), sans-serif;
}
.schedule-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background: linear-gradient(135deg, var(--lightblue), var(--lightgreen));
    overflow: hidden;
}
.schedule {
    /* background: rgba(255, 255, 255, 0.9); */
    background: rgba(234, 239, 242, 0.6); 
    backdrop-filter: blur(4px);
    padding: 30px;
    border-radius: 15px;
    box-shadow: var(--shadow);
    text-align: center;
    color: var(--black);
    width: 450px;
    position: relative;
    border: var(--inactive-border);
}
.schedule h2 {
    margin-bottom: 20px;
    color: var(--blue);
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}
.schedule-form {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.form-group {
    position: relative;
    display: flex;
    align-items: center;
    gap: 2px;
    flex-direction: column;
    align-items: flex-start;
    
    padding: 6px;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(2px);
    
}
label {

    color: var(--blue);
    font-weight: bold;
    text-align: left;
}
input {

    padding: 10px;
    border: none;
    border-radius: 15px;
    outline: none;
    font-size: 14px;
    width: 100%;
    background: rgba(132, 189, 186, 0.425);
    color: var(--black);
    border: var(--inactive-border);
}
input::placeholder {
    color: var(--darkinactive);
}
button {
    background: var(--blue);
    color: var(--white);
    border: none;
    padding: 12px;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
    transition: var(--transition);
    width: 100%;
    border: var(--inactive-border);
}
button:hover {
    background: var(--lightblue);
}
.info-icon {
    color: var(--blue);
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    position: absolute;
    right: 10px;
}
.popup {
    position: absolute;
    background: var(--darkinactive);
    color: var(--white);
    padding: 10px;
    border-radius: 5px;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    white-space: nowrap;
}

#comments{
    width:100%;
}


#logo{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    opacity:0.7;
}



</style>
